type AppCash = {
	finOperations?: {
		isAvailable?: boolean;
	};
};

type OrderSummary = {
	promoOfferPreset?: {
		bestDeal?: string | boolean;
		mostPopular?: string | boolean;
		coins?: string | number;
		entries?: number | string;
	};
	preset?: {
		coins?: string | number;
		entries?: number | string;
	};
	money?: number | string;
};

const useCashHelper = () => {
	const appCashData = computed(() => window?.$cash?.$store?.getters["cash/appCash"] as AppCash);
	const cashOrderSummary = computed(() => window?.$cash?.$store?.getters["cash/orderSummary"] as OrderSummary);
	const cashPaymentMethod = computed(() => window?.$cash?.$store?.getters["cash/paymentMethod"] as string);

	const isFinOperationAvailable = computed(() => appCashData.value?.finOperations?.isAvailable);

	return {
		isFinOperationAvailable,
		cashOrderSummary,
		cashPaymentMethod
	};
};

export default useCashHelper;
